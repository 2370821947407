<template>
    <div>
        <div class="commonInputTopBox">
            <pageHeader :pagename="'停车业务管理'" :total="pageCurrent.total" />

            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="选择车库">
                    <el-input
                        placeholder="车库ID"
                        v-model="data.garage_id"
                        @click.native="selectGarageDialog = true"
                    ></el-input>
                </el-form-item>

                <el-form-item label="已选车库:" v-if="selectGarageItem">
                    {{ selectGarageItem.info_name }}
                    <el-button
                        type="danger"
                        size="mini"
                        @click="closegarageselect"
                        >取消选中</el-button
                    >
                </el-form-item>

                <el-form-item label="车牌号">
                    <el-input
                        placeholder="车牌号码"
                        v-model="data.carport"
                    ></el-input>
                </el-form-item>

                <el-form-item label="手机号">
                    <el-input
                        placeholder="车主手机号"
                        v-model="data.phone"
                    ></el-input>
                </el-form-item>

                <el-form-item style="float: right">
                    <el-button type="primary" @click="getList">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="layoutTabaleBorder">
            <el-table
                :data="pageCurrent.tableData"
                style="width: 100%"
                class="ones"
            >
                <el-table-column
                    prop="id"
                    label="系统ID"
                    width="80"
                    align="center"
                >
                </el-table-column>
                <el-table-column prop="garage.info_name" label="车库信息">
                </el-table-column>
                <el-table-column prop="inpark_starttime" label="开始停车时间">
                </el-table-column>
                <el-table-column
                    prop="manual_service"
                    label="停车类型"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    prop="parkstatus"
                    label="停车状态"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    prop="parktime_count"
                    label="累计停车时长"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    prop="car.license_plate"
                    label="车牌号"
                    align="center"
                >
                </el-table-column>

                <el-table-column
                    prop="platform_type"
                    label="用户名"
                    align="center"
                ></el-table-column>

                <!-- <el-table-column prop="charging_snapshot.hourstop_price" label="每小时收费(快照)" width="100"> </el-table-column>
            <el-table-column prop="charging_snapshot.is24hour_capping_price" label="24小时封顶价格(快照)" width="120"> </el-table-column> -->
                <el-table-column
                    prop="parking_fee"
                    label="已产生费用"
                    align="center"
                >
                </el-table-column>

                <el-table-column label="是否月保车辆" align="center">
                    <template slot-scope="scope">
                        <template v-if="scope.row.car.monthly"
                            >月保车辆</template
                        >
                        <template v-else>否</template>
                    </template>
                </el-table-column>

                <el-table-column
                    label="更多"
                    fixed="right"
                    width="140"
                    align="center"
                >
                    <template slot-scope="scope">
                        <el-button @click="editAccount(scope.row.id)"
                            >查看详情</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div class="layoutBorder">
            <Pagination
                :total="pageCurrent.total"
                :page.sync="pageCurrent.currentPage"
                @pagination="getList()"
                :limit.sync="pageCurrent.pageSize"
            />
        </div>
    </div>
</template>

<script>
// 载入接口
// import { 接口函数 } from "@/api/接口函数定义文件";
import { parkinglog_query } from '@/api/partner/garageManage/parkingRecordManage/parklog.js';

// 载入方法
// import { 方法名 } from "@/utils/js文件名";

// 载入组件
// import 组件名 from "@/components/组件文件.vue";
import Pagination from '@/components/Pagination.vue';

export default {
    name: 'parkingRecordManage',
    data() {
        return {
            selectGarageDialog: false,
            selectGarageItem: null,
            data: {
                garage_id: null,
                park_status: null,
                carport: null,
                phone: null
            },
            variate: {},
            status: {},
            doc: {
                status_dict: {
                    1: '正常',
                    2: '禁用',
                    3: '黑名单'
                }
            },
            pageCurrent: {
                tableData: [],
                currentPage: 1,
                total: 0,
                pageCount: 0
            }
        };
    },
    components: {
        Pagination
    },
    created() {
        this.getList();
    },
    methods: {
        getList() {
            
            parkinglog_query({
                querypage: this.pageCurrent.currentPage,
                garage_id: this.data.garage_id,
                park_status: this.data.park_status,
                carport: this.data.carport,
                phone: this.data.phone
            }).then(res => {
                    if (res.code == 200) {
                        this.pageCurrent.total = res.data.total;
                        this.pageCurrent.currentPage = res.data.currentPage;
                        this.pageCurrent.pageCount = res.data.pageCount;
                        this.pageCurrent.tableData = res.data.result;
                        console.log(this.pageCurrent.tableData, 'tableData');
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error',
                            duration: 5 * 1000
                        });
                    }
                })
                
        },
        editAccount(ids) {
            console.log(ids);
            this.$router.push({ name: 'parkRecords_Info', query: { id: ids } });
        },
        callback(item) {
            console.log('item', item);
            this.data.garage_id = item.garage_id;
        },
        closegarageselect() {
            this.data.garage_id = null;
            this.selectGarageItem = null;
        }
    }
};
</script>

<style type="scss">
.demo-table-expand {
    background-color: #f7f9fc;
    padding: 40px;
    border-radius: 16px;
    font-size: 0;
    margin-left: 130px;
    width: 77%;
}
.demo-table-expand label {
    width: 260px;
    color: #99a9bf;
}
.demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
}
</style>
